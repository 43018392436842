import {
  Alert,
  Anchor,
  Button,
  Container,
  PasswordInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconKey, IconMail, IconUser } from '@tabler/icons-react';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import i18next from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { PRODUCT_NAME } from '../../Constants';
import { useUserQuery } from '../../models/User';
import { UpdateUserValues } from '../../queries/UserQueries';

interface EditAccountFormProps {
  onSubmit: (
    values: UpdateUserValues,
    formikHelpers: FormikHelpers<UpdateUserValues>,
  ) => Promise<void>;
}

const resendVerificationEmail = (t: typeof i18next.t) => {
  Api.ky
    .get(ApiRoutes.VerifyEmail)
    .then(() => {
      showNotification({
        title: t('translation:account.verificationEmailSentTitle'),
        message: t('translation:account.verificationEmailSentMessage'),
      });
    })
    .catch((err: Error) => {
      throw new Error(
        `Could not request resend of verification email: ${err.toString()}`,
      );
    });
};

const EditUserSchema = Yup.object().shape({
  username: Yup.string(),
  email: Yup.string().email().required(),
  password: Yup.string(),
});

export const EditAccountForm: React.FC<EditAccountFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();

  const initialValues: UpdateUserValues = {
    username: user?.username ?? '',
    email: user?.email ?? '',
    password: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditUserSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        errors,
        dirty,
        touched,
        isValid,
        isSubmitting,
        handleSubmit,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack gap="lg">
            <TextInput
              label={t('account.usernameLabel')}
              leftSection={<IconUser />}
              placeholder={t('account.usernamePlaceholder')}
              {...getFieldProps('username')}
              error={touched.username && errors.username}
            />
            {user && !user.isActive && (
              <Alert
                title={t('account.verifyEmailAddressTitle')}
                icon={<IconMail />}
              >
                <Trans
                  i18nKey="account.verifyEmailAddressMessage"
                  brand={PRODUCT_NAME}
                >
                  Please check your email inbox and verify your address to fully
                  activate your account! If you did not receive an email from
                  ResuFit, please{' '}
                  <Anchor fz="sm" onClick={() => resendVerificationEmail(t)}>
                    click here to re-send it
                  </Anchor>
                  .
                </Trans>
              </Alert>
            )}
            <TextInput
              label={t('account.emailLabel')}
              leftSection={<IconMail />}
              placeholder={t('account.emailPlaceholder')}
              withAsterisk
              {...getFieldProps('email')}
              error={touched.email && errors.email}
            />

            <PasswordInput
              label={t('account.passwordLabel')}
              autoComplete="new-password"
              leftSection={<IconKey />}
              placeholder={t('account.passwordPlaceholder')}
              {...getFieldProps('password')}
              error={touched.password && errors.password}
            />
            <Container mt="xl">
              <Button
                type="submit"
                size="md"
                variant="filled"
                loading={isSubmitting}
                leftSection={<IconCheck />}
                disabled={!(dirty && !isSubmitting && isValid)}
              >
                {t('account.saveButton')}
              </Button>
            </Container>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
