import {
  Center,
  Container,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Logger } from 'tslog';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { Bowl } from '../../components/Bowl/Bowl';
import { IAnalysis } from '../../models/Analysis';
import Tracking from '../../services/Tracking';
import { AnalysisRating } from './AnalysisRating';
import { AnalysisSection } from './AnalysisSection';
import { QUERY_KEY_ANALYSIS } from './ImportProfileModal';
import { ResumeDropzone } from './ResumeDropzone';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = new Logger({ name: 'AnalyzeResumePage' });

export const AnalyzeResumePage: React.FC = () => {
  const [isAnalyzing, setIsAnalyzing] = React.useState(false);
  const [error, setError] = React.useState(false);

  const useAnalysisQuery = (enableRefetch: boolean) =>
    useQuery({
      queryKey: ['analysis'],
      queryFn: () => Api.ky.get(`${ApiRoutes.Analysis}`).json<IAnalysis>(),
      refetchInterval: enableRefetch ? 2000 : false,
    });

  const mutation = useMutation({
    mutationFn: (file: FileWithPath) =>
      Api.upload(ApiRoutes.Analysis, file).text(),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_ANALYSIS }),
  });
  const queryClient = useQueryClient();

  const { data: analysisData, isSuccess } = useAnalysisQuery(isAnalyzing);

  const { t } = useTranslation();

  const onFileSelected = async (file: FileWithPath) => {
    Tracking.event('analyze_cv');
    try {
      showNotification({
        id: 'profile-import',
        title: t('analyze.analyzingTitle'),
        message: t('analyze.analyzingMessage'),
        loading: true,
        autoClose: 10000,
      });

      setIsAnalyzing(true);
      setError(false);
      await mutation.mutateAsync(file);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (analysisData) {
      setIsAnalyzing(analysisData.status === 'pending');
    }
    if (analysisData?.status === 'error') {
      setError(true);
    }
  }, [analysisData?.status]);

  return (
    <Container pb="xl" mb="xl">
      <Bowl title={t('analyze.pageTitle')} />
      <Group>
        <Title order={2}>{t('analyze.pageTitle')}</Title>
        {analysisData && !isAnalyzing && !error && (
          <AnalysisRating rating={analysisData?.analysis?.rating} />
        )}
      </Group>
      {isAnalyzing ? (
        <Center m="120px">
          <Stack gap="xl" align="center" m="xl">
            <Loader color="blue" size="xl" type="bars" />
            <Title order={3}>{t('analyze.analyzingMessage')}</Title>
            <Text c="dimmed">{t('analyze.analyzingInfo')}</Text>
          </Stack>
        </Center>
      ) : error ? (
        <>
          <Text c="red.8" my="xl">
            {t('analysisData.analysisError')}
          </Text>
          <ResumeDropzone onFileSelected={onFileSelected} />
        </>
      ) : (
        <Stack gap="xl" mt="xl">
          {analysisData && <AnalysisSection analysisData={analysisData} />}
          <ResumeDropzone
            onFileSelected={onFileSelected}
            withWarning={
              !isSuccess && (!analysisData || analysisData.status !== 'pending')
            }
          />
        </Stack>
      )}
    </Container>
  );
};
