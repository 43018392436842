import * as Sentry from '@sentry/react';
import { IUser } from '../models/User';
import { hashString } from '../utils';
import { getLogger } from './Logger';

const logger = getLogger('Tracking');

export interface EventParams {
  begin_checkout: {
    items: [{ item_id: string }];
    currency: string;
    value: number;
    eventCallback?: () => void;
  };
  purchase: {
    items: [{ item_id: string }];
    currency: string;
    value: number;
    transaction_id?: string;
  };
  sign_up: {
    method: 'email' | 'google' | 'apple';
  };
  login: {
    method: string;
  };
  generate: never;
  analyze_cv: never;
  import_from_analysis: never;
  profile_updated: never;
  profile_import: never;
}

export interface CheckoutParams {
  itemId: string;
  currency: string;
  value: number;
  transactionId?: string;
}

window.dataLayer = window.dataLayer || [];
window.resuFitData = window.resuFitData || {};

window.gtag =
  window.gtag ||
  function <Command extends keyof Gtag.GtagCommands>(
    command: Command,
    ...args: Gtag.GtagCommands[Command]
  ) {
    window.dataLayer.push(arguments);
  };

class Tracking {
  static setUser(user: IUser, ip?: string) {
    Sentry.setUser({
      id: user.id,
      username: user.username,
      ip_address: ip || '{{auto}}',
    });

    window.sib = {
      ...window.sib,
      email_id: user.email,
    };

    Tracking.setUserData('user_id', user.id);

    hashString(user.email)
      .then((emailHash) => {
        Tracking.setUserData('sha256_email_address', emailHash);
      })
      .catch((err) => {
        logger.error('Error while hashing email:', err);
      });
  }

  static clearUser() {
    Sentry.setUser(null);
    Tracking.setUserData('user_id', null);
    Tracking.setUserData('sha256_email_address', null);
  }

  static setUserData(key: string, value: string | number | boolean | null) {
    window.dataLayer.push({
      user_data: { [key]: value },
    });
  }

  static trackBeginCheckout({ itemId, currency, value }: CheckoutParams) {
    return new Promise<void>((resolve) => {
      let eventSent = false;

      const fallbackTimeout = setTimeout(() => {
        if (!eventSent) {
          eventSent = true;
          resolve();
        }
      }, 500);

      Tracking.event('begin_checkout', {
        currency,
        value,
        items: [{ item_id: itemId }],
        eventCallback: function () {
          if (!eventSent) {
            eventSent = true;
            clearTimeout(fallbackTimeout);
            resolve();
          }
        },
      });
    });
  }

  static trackPurchase({
    itemId,
    currency,
    value,
    transactionId,
  }: CheckoutParams) {
    Tracking.event('purchase', {
      currency,
      value,
      items: [{ item_id: itemId }],
      transaction_id: transactionId,
    });
  }

  static trackSignup({
    email,
    method,
  }: {
    email?: string;
    method: 'email' | 'google' | 'apple';
  }) {
    if (!email) {
      Tracking.event('sign_up', { method });
      return Promise.resolve();
    }

    return hashString(email)
      .then((emailHash) => {
        Tracking.setUserData('sha256_email_address', emailHash);
        Tracking.event('sign_up', { method });
      })
      .catch((err) => {
        logger.error('Error while hashing email:', err);
      });
  }

  static event<T extends keyof EventParams>(
    eventName: T,
    ...params: EventParams[T] extends never ? [] : [EventParams[T]]
  ): void {
    if (params.length > 0) {
      window.dataLayer.push({ event: eventName, ...params[0] });
    } else {
      window.dataLayer.push({ event: eventName });
    }
  }

  static trackLogin({ method }: { method: string }) {
    Tracking.event('login', { method });
  }

  static setConsent(consentStatus: Gtag.ConsentParams) {
    gtag('consent', 'update', consentStatus);
  }

  static consentAll() {
    Tracking.setConsent({
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });
  }

  static denyAll() {
    Tracking.setConsent({
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });
  }
}

export default Tracking;
